import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/Seo'
import { dates } from '../constants'

const Thanks = (props) => (
    <Layout>
        <SEO
        title="Reportáze &amp; Fotky"
        description={`Reportáže a fotogalerie ze hry Navíc, která se konala v Praze ${dates.game}.`}
        />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Reportáže &amp; Fotky</h2>
                    </header>
					<ul>
						<li><a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1SCmTjN1j7BtLfsyhdDYUbtSP5ktw0uoH4tZSA65RBnY/edit">Reportáž týmu ˇ&lt;</a></li>
						<li><a target="_blank" rel="noopener noreferrer" href="https://photos.app.goo.gl/ZUmYgWSri7jTXqZBA">Orgovská fotogalerie</a></li>
					</ul>
                </div>
            </section>
        </div>

    </Layout>
)

export default Thanks
